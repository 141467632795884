import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  iif,
  lastValueFrom,
  of,
} from 'rxjs';
import { Training } from './training.model';
import { FirebaseApiProvider } from 'libs/api/providers/firebase-api.provider';
import { filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { UnsubscriberService } from 'libs/unsubcriber/unsubscriber.service';
import { UserService } from 'libs/user/user.service';
import { User } from 'libs/user/user.model';
import { WhereQuery } from 'libs/api/api.provider';

@Injectable({
  providedIn: 'root',
})
export class TrainingService {
  private isListeningToTrainingIds: string[] = [];
  private isListeningToAllTrainings = false;
  private trainingsSubject: BehaviorSubject<Training[]> = new BehaviorSubject(
    []
  );
  trainings$: Observable<Training[]> = this.trainingsSubject.asObservable();

  private currentTrainingIdSubject: BehaviorSubject<string> =
    new BehaviorSubject(null);
  currentTrainingId$: Observable<string> =
    this.currentTrainingIdSubject.asObservable();

  constructor(
    private apiService: FirebaseApiProvider,
    private userService: UserService,
    private unsubscriberService: UnsubscriberService
  ) {}

  getAllTrainings(trainingCenterId?: string): Observable<Training[]> {
    if (!this.isListeningToAllTrainings) {
      console.log('getUserAllTrainings API CALL LISTENING');
      this.listenToAllTrainingChanges(trainingCenterId);
    } else {
      console.log('getUserAllTrainings FROM CACHE');
    }

    return this.trainings$;
  }

  getUserActiveTrainings(trainingIds: string[]): Observable<Training[]> {
    if (
      JSON.stringify(trainingIds) !==
        JSON.stringify(this.isListeningToTrainingIds) &&
      !this.isListeningToAllTrainings
    ) {
      console.log('getUserActiveTrainings API CALL LISTENING:', trainingIds);
      this.listenToTrainingChanges(trainingIds);
    } else {
      console.log('getUserActiveTrainings FROM CACHE', trainingIds);
    }

    return this.trainings$.pipe(
      filter<Training[]>((trainings) => trainings.length > 0),
      map((trainings) =>
        trainings.filter(
          (training) =>
            trainingIds.includes(training.id) && training.isActive === true
        )
      )
    );
  }

  private listenToAllTrainingChanges(trainingCenterId?: string) {
    this.isListeningToAllTrainings = true;

    const whereQ: WhereQuery[] = [];
    if (trainingCenterId) {
      whereQ.push({
        fieldPath: 'trainingCenterId',
        condition: '==',
        value: trainingCenterId,
      });
    }

    const trainingsSubscriber = this.apiService
      .listenToChanges<Training>(
        'trainings',
        Training.fromObject,
        whereQ,
        'title'
      )
      .pipe(
        finalize(() => {
          this.isListeningToAllTrainings = false;
          console.log('listenToAllTrainingChanges finalized.');
        })
      )
      .subscribe((trainings) => {
        console.log('listenToAllTrainingChanges NEXT:', trainings);
        this.trainingsSubject.next(trainings);
      });
    this.unsubscriberService.add(
      'TrainingService:listenToAllTrainingChanges',
      trainingsSubscriber
    );
  }

  private listenToTrainingChanges(trainingIds: string[]) {
    if (this.trainingsSubject.getValue()) {
      this.trainingsSubject.next([]);
    }
    this.unsubscriberService.unsubscribe(
      'TrainingService:listenToTrainingChanges'
    );
    this.isListeningToTrainingIds = trainingIds;

    const trainingsSubscriber = this.apiService
      .listenToChanges<Training>('trainings', Training.fromObject, [
        {
          fieldPath: 'id',
          condition: 'in',
          value: trainingIds,
        },
        {
          fieldPath: 'isActive',
          condition: '==',
          value: true,
        },
      ])
      .pipe(
        finalize(() => {
          this.isListeningToTrainingIds = [];
          console.log('listenToTrainingChanges finalized.');
        })
      )
      .subscribe((trainings) => {
        console.log('listenToTrainingChanges NEXT:', trainings);
        this.trainingsSubject.next(trainings);
      });
    this.unsubscriberService.add(
      'TrainingService:listenToTrainingChanges',
      trainingsSubscriber
    );
  }

  getUserTrainingById(trainingId: string): Observable<Training> {
    console.log('getUserTrainingById trainingId:', trainingId);
    if (
      !this.isListeningToTrainingIds.includes(trainingId) &&
      !this.isListeningToAllTrainings
    ) {
      this.getUserActiveTrainings([trainingId]);
    }

    const training$ = this.trainings$.pipe(
      filter<Training[]>((trainings) => trainings.length > 0),
      map((trainings) =>
        trainings.find((training) => training.id === trainingId)
      )
    );

    return this.withTrainingUsers(training$);
  }

  withTrainingUsers(training$: Observable<Training>): Observable<Training> {
    return training$.pipe(
      switchMap((training) => {
        if (!training?.apprentices) {
          return combineLatest([training$, of([])]);
        }

        const { apprenticeIds, tutorIds } = training.apprentices.reduce(
          (ids, apprentice) => {
            ids.apprenticeIds.push(apprentice.id);
            apprentice.tutors?.forEach((tutorId) => ids.tutorIds.push(tutorId));
            return ids;
          },
          { apprenticeIds: [], tutorIds: [] }
        );
        const trainingUserIds = Array.from(
          new Set([...apprenticeIds, ...tutorIds, ...training.managers])
        );

        const trainingUsers$ = this.userService
          .getUsers(trainingUserIds)
          .pipe(
            map((users) =>
              users.filter((user) => trainingUserIds.includes(user.id))
            )
          );

        return combineLatest([training$, trainingUsers$]);
      }),
      map(([training, users]) => {
        if (users.length > 0) {
          users.forEach(
            (user) => (training.users[`${user.role}s`][user.id] = user)
          );
          // training.users.apprentices = { ...training.users.apprentices, ...training.users.alumnis };
          if (training.isArchived === true) {
            training.users.apprentices = training.users.alumnis;
          } else {
            training.apprentices?.forEach((apprentice) => {
              training.users.apprentices[apprentice.id].tutors =
                apprentice.tutors?.map(
                  (tutorId: string) => training.users.tutors[tutorId]
                );
            });
          }
        }

        return training;
      })
    );
  }

  async unlinkUserFromTraining(
    currentTraining: Training,
    user: User,
    linkedUser?: User,
    update = true
  ): Promise<Training> {
    try {
      switch (user.role) {
        case 'manager':
          const managerIndex = currentTraining.managers.findIndex(
            (managerId) => managerId === user.id
          );
          if (managerIndex !== -1) {
            currentTraining.managers.splice(managerIndex, 1);
          }
          break;
        case 'apprentice':
          const apprenticeIndex = currentTraining.apprentices.findIndex(
            (apprentice) => apprentice.id === user.id
          );
          if (apprenticeIndex !== -1) {
            currentTraining.apprentices.splice(apprenticeIndex, 1);
          }
          break;
        case 'tutor':
          if (linkedUser) {
            const tutorApprenticeIndex = currentTraining.apprentices.findIndex(
              (apprentice) => apprentice.id == linkedUser.id
            );
            if (tutorApprenticeIndex !== -1) {
              const tutorIndex = currentTraining.apprentices[
                tutorApprenticeIndex
              ].tutors?.findIndex((tutorId) => tutorId === user.id);
              if (tutorIndex !== -1) {
                currentTraining.apprentices[tutorApprenticeIndex].tutors.splice(
                  tutorIndex,
                  1
                );
              }
            }
          }
      }

      if (update) {
        this.apiService.update(
          `trainings/${currentTraining.id}`,
          currentTraining,
          Training.toObject
        );
      }

      return currentTraining;
    } catch (e) {
      throw e;
    }
  }

  async deleteTrainingUsers(
    currentTraining: Training,
    users: User[]
  ): Promise<Training> {
    try {
      for (const user of users) {
        currentTraining = await this.unlinkUserFromTraining(
          currentTraining,
          user,
          null,
          false
        );
      }
      console.log('unlink users currentTraining: ', currentTraining);
      this.apiService.update(
        `trainings/${currentTraining.id}`,
        currentTraining,
        Training.toObject
      );
      return currentTraining;
    } catch (e) {
      throw e;
    }
  }

  async deleteTraining(trainingId: string): Promise<void> {
    try {
      await this.apiService.delete('trainings', trainingId);
    } catch (e) {
      throw e;
    }
  }

  async createOrUpdateTraining(training: Training): Promise<Training> {
    try {
      if (!training?.id || training?.id === '') {
        console.log('create new training: ', training);

        if (await this.trainingExists(training)) {
          throw { code: 'create/training-exists' };
        }

        training.id = await this.apiService.create<Training>(
          'trainings',
          training,
          Training.toObject
        );
      } else {
        await this.apiService.update(
          `trainings/${training.id}`,
          training,
          Training.toObject
        );
      }

      return training;
    } catch (e) {
      throw e;
    }
  }

  async trainingExists(training: Training): Promise<Training | null> {
    const trainings = await this.apiService.fetchAll<Training>(
      'trainings',
      Training.fromObject,
      [
        {
          fieldPath: 'title',
          condition: '==',
          value: training?.title,
        },
      ]
    );
    return trainings?.length > 0 ? trainings[0] : null;
  }

  async linkUserToTraining(
    currentTraining: Training,
    user: User,
    linkedUser?: User,
    update = true
  ): Promise<Training> {
    try {
      switch (user.role) {
        case 'manager':
          if (!currentTraining.managers.includes(user.id)) {
            currentTraining.managers.push(user.id);
          }
          break;
        case 'apprentice':
          if (
            currentTraining.apprentices.filter((a) => a.id === user.id)
              .length === 0
          ) {
            currentTraining.apprentices.push({ id: user.id, tutors: [] });
          }
          break;
        case 'tutor':
          if (linkedUser) {
            let apprenticeIndex = currentTraining.apprentices.findIndex(
              (apprentice) => apprentice.id === linkedUser.id
            );
            if (
              !currentTraining.apprentices[apprenticeIndex].tutors.includes(
                user.id
              )
            ) {
              currentTraining.apprentices[apprenticeIndex].tutors.push(user.id);
            }
          }
          break;
      }

      if (update) {
        this.apiService.update(
          `trainings/${currentTraining.id}`,
          currentTraining,
          Training.toObject
        );
      }

      return currentTraining;
    } catch (e) {
      throw e;
    }
  }

  setCurrentTrainingId(trainingId: string) {
    this.currentTrainingIdSubject.next(trainingId);
  }

  getCurrentTrainingId() {
    return this.currentTrainingIdSubject.getValue();
  }
}
