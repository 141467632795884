import { Injectable } from '@angular/core';
import {
  AlertController,
  LoadingController,
  ModalController,
  ModalOptions,
  Platform,
  PopoverController,
  ToastController,
  ToastOptions,
} from '@ionic/angular';
import { isPlatform } from '@ionic/angular';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import { AppUpdate, AppUpdateInfo } from '@capawesome/capacitor-app-update';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  isLoading: boolean = false;

  constructor(
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private platform: Platform
  ) {}

  setLoader() {
    this.isLoading = !this.isLoading;
  }

  showAlert(message: string, header?, buttonArray?, inputs?) {
    this.alertCtrl
      .create({
        header: header ? header : `L'Authentifcation a échouée`,
        message: message,
        inputs: inputs ? inputs : [],
        buttons: buttonArray ? buttonArray : ['Ok'],
      })
      .then((alertEl) => alertEl.present());
  }

  async showToast({
    msg,
    color,
    position,
    duration = 5000,
    header,
    buttonArray,
    cssClass = 'toast-message',
  }: {
    msg: string;
    color: string;
    position: ToastOptions['position'];
    duration?: number;
    header?: string;
    buttonArray?: [];
    cssClass?: string;
  }) {
    const toastOptions: ToastOptions = {
      message: msg,
      duration: duration,
      color: color,
      position: position,
    };
    if (header) {
      toastOptions['header'] = header;
    }
    if (buttonArray) {
      toastOptions['buttons'] = buttonArray;
    }
    if (cssClass) {
      toastOptions['cssClass'] = cssClass;
    }
    const toast = await this.toastCtrl.create(toastOptions);
    toast.present();
  }

  errorToast(msg?, duration = 5000) {
    this.showToast({
      msg: msg ? msg : 'Pas de connection internet',
      color: 'danger',
      position: 'top',
      duration,
    });
  }

  successToast(msg) {
    this.showToast({ msg, color: 'success', position: 'bottom' });
  }

  messageToast(header: string, msg: string, buttonArray?) {
    this.showToast({
      msg,
      color: 'primary',
      position: 'top',
      duration: 5000,
      header,
      buttonArray,
    });
  }

  async showLoader(msg?: string, spinner?: any) {
    if (!this.isLoading) this.setLoader();
    const loader = await this.loadingCtrl.create({
      message: msg,
      spinner: spinner ? spinner : 'bubbles',
    });
    await loader.present();
  }

  hideLoader() {
    if (this.isLoading) this.setLoader();
    return this.loadingCtrl
      .dismiss()
      .then(() => console.log('dismissed'))
      .catch((e) => console.log('error hide loader: ', e));
  }

  async createModal(options: ModalOptions) {
    const modal = await this.modalCtrl.create(options);
    await modal.present();
    const { data } = await modal.onWillDismiss();
    console.log(data);
    if (data) return data;
  }

  modalDismiss(val?) {
    let data: any = val ? val : null;
    console.log('modalDismiss data', data);
    this.modalCtrl.dismiss(data);
  }

  async createConfirm(
    header: string,
    message: string,
    confirmText: string,
    canCancel = true,
    backdropDismiss = true,
    inputs?
  ) {
    const options = {
      header,
      message,
      inputs: inputs ? inputs : [],
      backdropDismiss,
      buttons: [
        {
          text: confirmText,
          role: 'confirm',
          cssClass: 'alert-button-confirm',
        },
      ],
    };
    if (canCancel) {
      options.buttons.push({
        text: 'Annuler',
        role: 'cancel',
        cssClass: 'alert-button-cancel',
      });
    }
    const alert = await this.alertCtrl.create(options);
    await alert.present();
    const { role } = await alert.onDidDismiss();
    console.log(role);
    if (role) return role;
  }

  async createPopover(options) {
    const popover = await this.popoverCtrl.create(options);
    await popover.present();
    const { data } = await popover.onWillDismiss();
    console.log(data);
    if (data) return data;
  }

  getIcon(title) {
    const name = title.toLowerCase();
    switch (name) {
      case 'home':
        return 'home-outline';
      case 'work':
        return 'briefcase-outline';
      default:
        return 'location-outline';
    }
  }

  async customStatusbar() {
    if (Capacitor.getPlatform() != 'web') {
      await StatusBar.setStyle({ style: Style.Light });
      if (isPlatform('android'))
        StatusBar.setBackgroundColor({ color: '#e6f3ff' });
    }
  }

  sortArrayData(data: any[], key: string, asc: boolean = true): any[] {
    console.log('sortArrayData', data);
    return data.sort((a: any, b: any) => {
      let fa = a[key];
      let fb = b[key];

      if (typeof fa === 'string') {
        fa = fa.toLowerCase();
      }

      if (typeof fb === 'string') {
        fb = fb.toLowerCase();
      }

      if (fa < fb) {
        return asc ? -1 : 1;
      }
      if (fa > fb) {
        return asc ? 1 : -1;
      }
      return 0;
    });
  }

  disableLogOnProd(isProd = false) {
    if (isProd) {
      console.log = console.error = () => {};
    }
  }

  async checkIfUpdateIsAvailable() {
    if (!this.platform.is('capacitor')) {
      return;
    }

    const getCurrentAppVersion = (result: AppUpdateInfo) => {
      if (Capacitor.getPlatform() === 'android') {
        return result.currentVersionCode;
      } else {
        return result.currentVersionName;
      }
    };

    const getAvailableAppVersion = (result: AppUpdateInfo) => {
      if (Capacitor.getPlatform() === 'android') {
        return result.availableVersionCode;
      } else {
        return result.availableVersionName;
      }
    };

    try {
      const appUpdateInfo: AppUpdateInfo = await AppUpdate.getAppUpdateInfo({
        country: 'fr',
      });
      const currentVersion = getCurrentAppVersion(appUpdateInfo);
      const availableVersion = getAvailableAppVersion(appUpdateInfo);
      console.log('CURRENT VERSION', currentVersion);
      console.log('AVAILABLE VERSION', availableVersion);
      console.log('NEED UPDATE?', currentVersion < availableVersion);
      if (currentVersion < availableVersion) {
        const confirm = await this.createConfirm(
          'Mise à jour disponible',
          "Une nouvelle version de Link Up est disponible sur le store, veuillez la télécharger pour continuer à utiliser l'application.",
          'Télécharger',
          false,
          false
        );
        if (confirm === 'confirm') {
          await AppUpdate.openAppStore({ country: 'fr' });
        }
      }
      // return appUpdateInfo;
    } catch (e) {
      console.error(e);
      // return {
      //   version: 'inconnue',
      //   error: e,
      // };
    }
  }
}
